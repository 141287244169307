import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    id: null,
    token: null,
    name: null,
    delivery_addresses: []
  }),
  getters: {
    isLogged(state) {
      return state.token !== null
    }
  },
  actions: {
    setUserDetails(user) {
      this.id = user.id
      this.token = user.token
      this.name = user.name
      this.delivery_addresses = user.delivery_addresses
    },
    logoutUser() {
      this.id = null
      this.token = null
      this.name = null
      this.delivery_addresses = []
    },
    setDeliveryAddress(address) {
      const index = this.delivery_addresses.map(item => item.id).indexOf(address.id)
      if (index !== -1) {
        this.delivery_addresses[index] = address
      } else {
        this.delivery_addresses.push(address)
      }
    }
  },
  persist: true
})
