const routes = [
  {
    path: '',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'homepage',
        component: () => import('../pages/ProductList.vue')
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('../layouts/Account.vue'),
        children: [
          { path: '', component: () => import('../pages/Account/Details.vue'), name: 'account.details' },
          { path: 'orders', component: () => import('../pages/Account/Orders.vue'), name: 'account.orders' },
          {
            path: 'benefits',
            component: () => import('../pages/Account/Benefits.vue'),
            name: 'account.bonus-points'
          }
        ],
        meta: { requiresAuth: true }
      },
      { path: 'reservations', component: () => import('../pages/Reservations.vue'), name: 'reservations' },
      {
        path: 'reservations/thank-you',
        component: () => import('../pages/ThankYou.vue'),
        name: 'reservations.thank-you'
      },
      { path: 'events', component: () => import('../pages/Events.vue'), name: 'events' },
      { path: 'event/:url', component: () => import('../pages/EventPage.vue'), name: 'events.page' },
      { path: 'jobs', component: () => import('../pages/Jobs/List.vue'), name: 'jobs.list' },
      { path: 'job/:url', component: () => import('../pages/Jobs/View.vue'), name: 'jobs.view' },
      { path: 'legal/:type', component: () => import('../pages/Legal.vue'), name: 'legal' },
      { path: 'contact', component: () => import('../pages/Contact.vue'), name: 'contact' },
      { path: 'contact/:storeUrl', component: () => import('../pages/ContactStore.vue'), name: 'contact.store' },
      { path: 'unsubscribe', component: () => import('../pages/Unsubscribe.vue'), name: 'unsubscribe' },
      {
        path: 'order-success/:orderToken',
        component: () => import('../pages/Cart/OrderSuccess.vue'),
        name: 'order-success.title'
      },
      {
        path: 'order-success',
        component: () => import('../pages/Cart/OrderSuccess.vue'),
        name: 'order-success.title.vivawallet'
      },
      {
        path: 'page/:type',
        name: 'static-page',
        component: () => import('../pages/PageStatic.vue')
      },
      {
        name: 'product-page',
        path: 'p/:productUrl/:productId',
        component: () => import('../pages/Product.vue')
      },
      {
        path: 'page/:type/:url/:id',
        name: 'static-page2',
        component: () => import('../pages/PageStatic.vue')
      },
      {
        path: ':menuName/:menuId',
        name: 'menu-page',
        component: () => import('../pages/ProductList.vue'),
        children: [
          {
            name: 'category-page2',
            path: ':categoryUrl/:categoryId',
            component: () => import('../pages/ProductList.vue')
          }
        ]
      },
      {
        path: ':categoryUrl/:categoryId/:menuId',
        name: 'category-page',
        component: () => import('../pages/ProductList.vue')
      },
      {
        path: 's/:storeUrl',
        name: 'store-page',
        component: () => import('../pages/ProductList.vue')
      },
      {
        path: ':type',
        name: 'static-page-url',
        component: () => import('../pages/PageStatic.vue')
      },
      { path: '/test', component: () => import('../pages/Test.vue') },
      {
        path: 'group-orders/cancelled',
        component: () => import('../pages/Cart/GroupOrderCancelled.vue'),
        name: 'group-orders-cancelled'
      },
      {
        path: 'group-orders/success',
        component: () => import('../pages/Cart/GroupOrderSuccess.vue'),
        name: 'group-orders-success'
      }
    ]
  },
  {
    path: '/integrations',
    component: () => import('../layouts/Cart.vue'),
    children: [
      {
        path: 'braintree/:orderId',
        component: () => import('../pages/Integration/Braintree.vue'),
        name: 'integrations-braintree'
      },
      {
        path: 'stripe/:orderId',
        component: () => import('../pages/Integration/Stripe.vue'),
        name: 'integrations-stripe'
      },
      {
        path: 'paypal/:orderId',
        component: () => import('../pages/Integration/PayPal.vue'),
        name: 'payment-methods.paypal'
      },
      {
        path: 'paypal-plus/:orderId',
        component: () => import('../pages/Integration/PayPalPlus.vue'),
        name: 'integrations-paypal-plus'
      },
      {
        path: 'kiosk-credit-card',
        component: () => import('../pages/Integration/KioskCreditCard.vue'),
        name: 'integrations-kiosk-credit-card'
      },
      {
        path: 'kiosk-success',
        component: () => import('../pages/Integration/KioskSuccess.vue'),
        name: 'integrations-kiosk-success'
      }
    ]
  },
  {
    path: '/cart',
    component: () => import('../layouts/Cart.vue'),
    children: [{ path: 'checkout', component: () => import('../pages/Cart/Checkout.vue'), name: 'checkout' }]
  },
  {
    path: '/auth',
    component: () => import('../layouts/Authentication.vue'),
    children: [
      { path: 'login', component: () => import('../pages/Authentication/Login.vue'), name: 'auth.login-btn' },
      { path: 'register', component: () => import('../pages/Authentication/Register.vue'), name: 'auth.register' },
      {
        path: 'new-password/:id',
        component: () => import('../pages/Authentication/NewPassword.vue'),
        name: 'auth.new-password'
      },
      {
        path: 'join-group-order',
        component: () => import('../pages/Authentication/JoinGroupOrder.vue'),
        name: 'cart.group-order.join-group-order'
      },
      {
        path: 'validate-phone-number',
        component: () => import('../pages/Authentication/ValidatePhoneNumber.vue'),
        name: 'auth.validate-phone-number'
      }
    ]
  },
  {
    path: '/500',
    component: () => import('../pages/Error/Error500.vue')
  },
  {
    path: '/404', // :catchAll(.*)*
    component: () => import('../pages/Error/Error404.vue')
  }
]

export default routes
