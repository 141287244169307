<template>
  <router-view />
</template>

<script>
import { Cookies, Platform, setCssVar } from 'quasar'
import { i18n } from 'src/boot/i18n'
import { mapState, mapStores } from 'pinia'
import { useSettingsStore } from 'src/stores/settings'
import { useMenusStore } from 'src/stores/menus'
import { useCategoriesStore } from 'src/stores/categories'
import { useCartStore } from 'src/stores/cart'
export default {
  name: 'App',
  preFetch({ store, ssrContext, redirect, currentRoute }) {
    if (['menu-page', 'category-page'].indexOf(currentRoute.name) !== -1 && isNaN(currentRoute.params.menuId)) {
      redirect(false, 404)
    } else {
      const storeSettings = useSettingsStore(store)
      return new Promise(resolve => {
        const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies

        let translateLanguage = null
        if (cookies.has('translate_language') && cookies.get('translate_language').length === 5) {
          translateLanguage = cookies.get('translate_language')
        }
        let platform = null
        if (process.env.SERVER) {
          if (typeof ssrContext.req !== 'undefined') {
            storeSettings.setCurrentDomain(ssrContext.req.hostname)
            if (
              typeof ssrContext.req.headers.accept !== 'undefined' &&
              ssrContext.req.headers.accept.indexOf('image/webp') !== -1
            ) {
              storeSettings.setAcceptWebp({ result: true })
            }
          }
          platform = Platform.parseSSR(ssrContext)
        } else {
          platform = Platform
        }
        const storeMenus = useMenusStore(store)
        const storeCategories = useCategoriesStore(store)
        storeSettings
          .fetch({
            device: platform.is.mobile ? 'mobile' : 'desktop',
            translateLanguage,
            storeUrl: currentRoute.name === 'store-page' ? currentRoute.params.storeUrl : null
          })
          .then(result => {
            // check if domain exists
            if (currentRoute.path !== '/404' && storeSettings.website.id === 0) {
              redirect(false, 404)
            }

            // set default store_id and delivery_type
            const cartStore = useCartStore(store)
            if (result.companyId === 204 && result.delivery_now === true) {
              cartStore.$patch({
                delivery_type: 'delivery'
              })
            }
            const token = cookies.has(`be_${process.env.BOOSTEAT_MODE}_cart_token`)
              ? cookies.get(`be_${process.env.BOOSTEAT_MODE}_cart_token`)
              : ''
            cartStore.getFromAPI(token).then(resultCart => {
              if (resultCart !== null) {
                cartStore.$patch(JSON.parse(resultCart))
                if (cartStore.voucher !== null && cartStore.voucher.value_currency === 0) {
                  cartStore.updateVoucher()
                }
              }
              // set language
              i18n.global.locale = storeSettings.localization.language

              if (currentRoute.name === 'store-page') {
                cartStore.$patch({
                  store_id: Number(result.store_id)
                })
              } else {
                // set default store_id and delivery_type
                if (storeSettings.stores.length > 0 && cartStore.store_id === null) {
                  cartStore.$patch({
                    store_id: Number(result.store_id)
                  })
                }
              }

              // set menu and categories
              storeMenus.setItems(result.menus)
              storeCategories.setItems(result.categories)
              const selectedMenu = storeMenus.items[0]
              storeMenus.setSelected(selectedMenu)
              if (typeof selectedMenu !== 'undefined') {
                storeCategories.fetch({ menuId: selectedMenu.id, translateLanguage }).then(() => {
                  if (storeCategories.items.length > 0) {
                    const selectedCategory = storeCategories.items[0]
                    storeCategories.setSelected(selectedCategory)
                    resolve()
                  } else {
                    resolve()
                  }
                })
              } else {
                resolve()
              }
            })
          })
      })
    }
  },
  computed: {
    ...mapStores(useCartStore),
    ...mapState(useCartStore, {
      geolocation: 'geolocation'
    }),
    ...mapState(useSettingsStore, {
      deliveryAreas: store => store.localization.delivery_areas,
      websiteColor: store => store.website.color,
      websiteColorFilter: store => store.website.color_filter
    })
  },
  created() {
    this.$q.iconMapFn = iconName => {
      const myIcons = {
        'app:menu-bars': 'img:https://assets.boosteat.com/shop-images/be-icons/menu-bars.svg',
        'app:about': 'img:https://assets.boosteat.com/shop-images/be-icons/about.svg',
        'app:jobs': 'img:https://assets.boosteat.com/shop-images/be-icons/jobs.svg',
        'app:account': 'img:https://assets.boosteat.com/shop-images/be-icons/account.svg',
        'app:add': 'img:https://assets.boosteat.com/shop-images/be-icons/add.svg',
        'app:back': 'img:https://assets.boosteat.com/shop-images/be-icons/back.svg',
        'app:bag-arrow': 'img:https://assets.boosteat.com/shop-images/be-icons/bag-arrow.svg',
        'app:bag-cart-green': 'img:https://assets.boosteat.com/shop-images/be-icons/bag-cart-green.svg',
        'app:cart': 'img:https://assets.boosteat.com/shop-images/be-icons/cart.svg',
        'app:cart-black': 'img:https://assets.boosteat.com/shop-images/be-icons/cart-black.svg',
        'app:clock': 'img:https://assets.boosteat.com/shop-images/be-icons/clock.svg',
        'app:copy-link': 'img:https://assets.boosteat.com/shop-images/be-icons/copy-link.svg',
        'app:choose-drink': 'img:https://assets.boosteat.com/shop-images/be-icons/choose-drink.svg',
        'app:choose-sauce': 'img:https://assets.boosteat.com/shop-images/be-icons/choose-sauce.svg',
        'app:close': 'img:https://assets.boosteat.com/shop-images/be-icons/close.svg',
        'app:dice': 'img:https://assets.boosteat.com/shop-images/be-icons/dice.svg',
        'app:close-circle': 'img:https://assets.boosteat.com/shop-images/be-icons/close-circle.svg',
        'app:account-details': 'img:https://assets.boosteat.com/shop-images/be-icons/account-details.svg',
        'app:change-password': 'img:https://assets.boosteat.com/shop-images/be-icons/change-password.svg',
        'app:change-password-black': 'img:https://assets.boosteat.com/shop-images/be-icons/change-password-black.svg',
        'app:contact': 'img:https://assets.boosteat.com/shop-images/be-icons/contact.svg',
        'app:contact-data': 'img:https://assets.boosteat.com/shop-images/be-icons/contact-data.svg',
        'app:delete': 'img:https://assets.boosteat.com/shop-images/be-icons/delete.svg',
        'app:delivery': 'img:https://assets.boosteat.com/shop-images/be-icons/delivery.svg',
        'app:discount': 'img:https://assets.boosteat.com/shop-images/be-icons/discount.svg',
        'app:discounts': 'img:https://assets.boosteat.com/shop-images/be-icons/discounts.svg',
        'app:drop-down': 'img:https://assets.boosteat.com/shop-images/be-icons/drop-down.svg',
        'app:events': 'img:https://assets.boosteat.com/shop-images/be-icons/events.svg',
        'app:filter-icon': 'img:https://assets.boosteat.com/shop-images/be-icons/filter-icon.svg',
        'app:group-order': 'img:https://assets.boosteat.com/shop-images/be-icons/group-order.svg',
        'app:hourglass': 'img:https://assets.boosteat.com/shop-images/be-icons/hourglass.svg',
        'app:location': 'img:https://assets.boosteat.com/shop-images/be-icons/location.svg',
        'app:log-out': 'img:https://assets.boosteat.com/shop-images/be-icons/log-out.svg',
        'app:loyalty': 'img:https://assets.boosteat.com/shop-images/be-icons/loyalty.svg',
        'app:menu': 'img:https://assets.boosteat.com/shop-images/be-icons/menu.svg',
        'app:unlock': 'img:https://assets.boosteat.com/shop-images/be-icons/unlock.svg',
        'app:navigation': 'img:https://assets.boosteat.com/shop-images/be-icons/navigation.svg',
        'app:navigation-button': 'img:https://assets.boosteat.com/shop-images/be-icons/navigation-button.svg',
        'app:no-accepting-orders': 'img:https://assets.boosteat.com/shop-images/be-icons/no-accepting-orders.svg',
        'app:order-cancelled': 'img:https://assets.boosteat.com/shop-images/be-icons/order-cancelled.svg',
        'app:note': 'img:https://assets.boosteat.com/shop-images/be-icons/note.svg',
        'app:orders-bag': 'img:https://assets.boosteat.com/shop-images/be-icons/orders-bag.svg',
        'app:payments-method': 'img:https://assets.boosteat.com/shop-images/be-icons/payments-method.svg',
        'app:pick-up': 'img:https://assets.boosteat.com/shop-images/be-icons/pick-up.svg',
        'app:reservations': 'img:https://assets.boosteat.com/shop-images/be-icons/reservations.svg',
        'app:reservation-accepted': 'img:https://assets.boosteat.com/shop-images/be-icons/reservation-accepted.svg',
        'app:reservation-cancelled': 'img:https://assets.boosteat.com/shop-images/be-icons/reservation-cancelled.svg',
        'app:search': 'img:https://assets.boosteat.com/shop-images/be-icons/search.svg',
        'app:see-password': 'img:https://assets.boosteat.com/shop-images/be-icons/see-password.svg',
        'app:select': 'img:https://assets.boosteat.com/shop-images/be-icons/select.svg',
        'app:setting-account': 'img:https://assets.boosteat.com/shop-images/be-icons/setting-account.svg',
        'app:share': 'img:https://assets.boosteat.com/shop-images/be-icons/share.svg',
        'app:shop-closed': 'img:https://assets.boosteat.com/shop-images/be-icons/shop-closed.svg',
        'app:ticket': 'img:https://assets.boosteat.com/shop-images/be-icons/ticket.svg',
        'app:unsee-paswword': 'img:https://assets.boosteat.com/shop-images/be-icons/unsee-paswword.svg',
        'app:table-ordering': 'img:https://assets.boosteat.com/shop-images/be-icons/table-ordering-black.svg',
        'app:warning': 'img:https://assets.boosteat.com/shop-images/be-icons/warning.svg',
        'app:qr-code': 'img:https://assets.boosteat.com/shop-images/be-icons/qr-code.svg',
        'app:fast-food': 'img:https://assets.boosteat.com/icons/categories/fast-food.svg',
        'app:allergen-4': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/eg.svg',
        'app:allergen-5': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/fish.svg',
        'app:allergen-1': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/gluten.svg',
        'app:allergen-2': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/lactates.svg',
        'app:allergen-14': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/molluscs.svg',
        'app:allergen-10': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/mustard.svg',
        'app:allergen-6': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/nuts-peanuts.svg',
        'app:allergen-11': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/seeds.svg',
        'app:allergen-8': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/seeds.svg',
        'app:allergen-13': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/seeds.svg',
        'app:allergen-3': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/shellfish.svg',
        'app:allergen-7': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/soya.svg',
        'app:allergen-9': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/celery.svg',
        'app:allergen-12': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/sulfur-dioxide.svg',
        'app:white-dot': 'img:https://assets.boosteat.com/shop-images/be-icons/white-dot.svg',
        'app:green-dot': 'img:https://assets.boosteat.com/shop-images/be-icons/green-dot.svg',
        'app:minus': 'img:https://assets.boosteat.com/shop-images/be-icons/minus.svg',
        'app:plus': 'img:https://assets.boosteat.com/shop-images/be-icons/plus.svg',
        'app:cutlery': 'img:https://assets.boosteat.com/shop-images/be-icons/cutlery.svg',
        'app:logout': 'img:https://assets.boosteat.com/shop-images/be-icons/logout.svg',
        'app:repeat': 'img:https://assets.boosteat.com/shop-images/be-icons/repeat.svg',
        'app:bonus-points': 'img:https://assets.boosteat.com/shop-images/be-icons/bonus-points.svg',
        'app:envelope': 'img:https://assets.boosteat.com/shop-images/be-icons/envelope.svg',
        'app:social-icon-apple': 'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-apple.svg',
        'app:social-icon-facebook':
          'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-facebook.svg',
        'app:social-icon-facebook-black':
          'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-facebook-black.svg',
        'app:social-icon-tripadvisor-black':
          'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-tripadvisor-black.svg',
        'app:social-icon-youtube-black':
          'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-youtube-black.svg',
        'app:social-icon-google': 'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-google.svg',
        'app:social-icon-google-black':
          'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-google-black.svg',
        'app:social-icon-instagram-black':
          'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-instagram-black.svg',
        'app:social-icon-tiktok-black': 'img:https://assets.boosteat.com/shop-images/be-icons/social/tiktok-black.svg',
        'app:checkbox-checked': 'img:https://assets.boosteat.com/shop-images/be-icons/checkbox_checked.svg',
        'app:checkbox-unchecked': 'img:https://assets.boosteat.com/shop-images/be-icons/checkbox_unchecked.svg',
        'app:radio-checked': 'img:https://assets.boosteat.com/shop-images/be-icons/radio_checked.svg',
        'app:radio-unchecked': 'img:https://assets.boosteat.com/shop-images/be-icons/radio_unchecked.svg',
        'app:circle-checkmark': 'img:https://assets.boosteat.com/shop-images/be-icons/circle-checkmark.svg',
        'app:circle-checkmark-filled':
          'img:https://assets.boosteat.com/shop-images/be-icons/circle-checkmark-filled.svg',
        'app:apple-pay': 'img:https://assets.boosteat.com/shop-images/be-icons/payments/apple-pay.svg',
        'app:apple-pay-black': 'img:https://assets.boosteat.com/shop-images/be-icons/payments/apple-pay-black.svg',
        'app:google-pay': 'img:https://assets.boosteat.com/shop-images/be-icons/payments/google-pay.svg',
        'app:google-pay-black': 'img:https://assets.boosteat.com/shop-images/be-icons/payments/google-pay-black.svg',
        'app:waiter': 'img:https://assets.boosteat.com/shop-images/be-icons/waiter.svg',
        'app:receipt-pos': 'img:https://assets.boosteat.com/shop-images/be-icons/receipt-pos.svg'
      }
      const icon = myIcons[iconName]
      if (icon !== void 0) {
        return { icon: icon }
      }
    }
    this.$q.iconSet.field.error = 'app:warning'
    this.$q.iconSet.field.clear = 'app:close'
    this.$q.iconSet.tabs.left = 'app:back'
    this.$q.iconSet.tabs.right = 'app:select'
    this.$q.iconSet.chevron.left = 'app:back'
    this.$q.iconSet.chevron.right = 'app:select'
    this.$q.iconSet.carousel.left = 'app:back'
    this.$q.iconSet.carousel.right = 'app:select'
    this.$q.iconSet.arrow.dropdown = 'app:drop-down'
    this.$q.iconSet.expansionItem.icon = 'app:drop-down'
  },
  mounted() {
    // set cookie language
    this.$q.cookies.set('be_lang', this.$i18n.locale, {
      expires: 365,
      path: '/',
      sameSite: 'Strict',
      secure: process.env.NODE_ENV !== 'development'
    })
    // backport for old cart cookie, TODO: remove in future
    // if (this.$q.cookies.has(`be_${process.env.BOOSTEAT_MODE}_cart`)) {
    //   this.cartStore.$patch(JSON.parse(JSON.stringify(this.$q.cookies.get(`be_${process.env.BOOSTEAT_MODE}_cart`))))
    //   this.$q.cookies.remove(`be_${process.env.BOOSTEAT_MODE}_cart`, {
    //     path: '/',
    //     sameSite: 'Strict',
    //     secure: process.env.NODE_ENV !== 'development'
    //   })
    // }
    try {
      this.$q.localStorage.set(`be_${process.env.BOOSTEAT_MODE}_cart_token`, JSON.stringify(this.cartStore.$state))
      this.cartStore.$subscribe(
        () => {
          if (
            this.$q.cookies.has(`be_${process.env.BOOSTEAT_MODE}_cart_token`) === false ||
            this.$q.localStorage.getItem(`be_${process.env.BOOSTEAT_MODE}_cart_token`) !==
              JSON.stringify(this.cartStore.$state)
          ) {
            this.$q.localStorage.set(
              `be_${process.env.BOOSTEAT_MODE}_cart_token`,
              JSON.stringify(this.cartStore.$state)
            )
            const token = this.$q.cookies.has(`be_${process.env.BOOSTEAT_MODE}_cart_token`)
              ? this.$q.cookies.get(`be_${process.env.BOOSTEAT_MODE}_cart_token`)
              : ''
            this.$axios
              .post('public/carts', {
                token: token,
                json_object: JSON.stringify(this.cartStore.$state)
              })
              .then(response => {
                this.$q.cookies.set(`be_${process.env.BOOSTEAT_MODE}_cart_token`, response.data.token, {
                  expires: 365,
                  path: '/',
                  sameSite: 'Strict',
                  secure: process.env.NODE_ENV !== 'development'
                })
                // save discount_free_products
                if (response.data.discount_free_products !== null) {
                  this.cartStore.$patch({
                    discount_free_products: response.data.discount_free_products
                  })
                }
              })
          }
        },
        { flush: 'sync' }
      )
    } catch (e) {
      console.error(e)
    }
    if (this.$q.cookies.has('translate_language') && this.$q.cookies.get('translate_language').length === 5) {
      const translateLanaguage = this.$q.cookies.get('translate_language').split('-')[0]
      if (['ro', 'en', 'es'].indexOf(translateLanaguage) !== -1) {
        this.$i18n.locale = translateLanaguage
      } else {
        this.$i18n.locale = 'en'
      }
    }
    if (typeof this.$route.query.traffic_source !== 'undefined') {
      this.cartStore.setTrafficSource(this.$route.query.traffic_source)
    }
    if (this.geolocation.lat !== null && this.deliveryAreas.length > 0) {
      this.cartStore.setDeliveryArea(this.deliveryAreas, this.geolocation)
    }
    if (this.websiteColor !== null) {
      setCssVar('accent', this.websiteColor)
      if (this.websiteColorFilter !== null) {
        document.body.style.setProperty('--accent-filter', this.websiteColorFilter)
      }
    }
  }
}
</script>
