import { defineStore } from 'pinia'
import { axiosInstance } from 'boot/axios'

export const useMenusStore = defineStore('menus', {
  state: () => ({
    items: [],
    selected: {
      name: '',
      url: '',
      id: null
    }
  }),
  actions: {
    fetch(filters) {
      let filtersQuery = ''
      if (filters.translateLanguage !== null) {
        filtersQuery += `?translate_language=${filters.translateLanguage}`
      }
      return axiosInstance
        .get(`public/menus${filtersQuery}`)
        .then(({ data }) => {
          this.items = data.menus
        })
        .catch(() => {
          this.items = []
        })
    },
    setSelected(menu) {
      if (typeof menu !== 'undefined') {
        this.selected = menu
      }
    },
    setItems(items) {
      this.items = items
    }
  }
})
