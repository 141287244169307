import { defineStore } from 'pinia'
import { axiosInstance } from 'boot/axios'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    website: {
      id: 0,
      name: '',
      background: null,
      login_background: null,
      logo: null,
      maps_api: null,
      phone: '',
      email: '',
      payment_card: null,
      color: null,
      color_filter: null,
      allow_ordering: true,
      cutlery: 0
    },
    localization: {
      currency: '',
      country_code: null,
      time_zone: 'GMT',
      delivery_areas: [],
      language: null,
      age_restriction: null
    },
    delivery_time: 0,
    pickup_time: 0,
    pages: [],
    stores: [],
    shop_status: {
      delivery_now: false,
      pickup_now: false,
      delivery_later: false,
      pickup_later: false,
      has_delivery: false,
      has_pickup: false,
      someone_has_delivery: false,
      someone_has_pickup: false,
      message: null,
      only_later_hours: [],
      only_later_hours_pickup: []
    },
    trackers: [],
    external_resources: [],
    company: {
      name: ''
    },
    has_banners: false,
    loyalty: {
      active: false
    },
    has_vouchers: false,
    show_pages: {
      about_us: true,
      events: true,
      jobs: false,
      reservations: true
    },
    footer_content: null,
    banners: [],
    meta_tags: [],
    assets_url: null,
    accept_webp: false,
    has_allergens: true,
    auto_open_geolocation: false,
    current_domain: '',
    socialMediaAccounts: []
  }),
  actions: {
    fetch(filters) {
      let filtersQuery = ''
      if (filters.translateLanguage !== null) {
        filtersQuery += `&translate_language=${filters.translateLanguage}`
      }
      if (filters.storeUrl !== null) {
        filtersQuery += `&store_url=${filters.storeUrl}`
      }
      return axiosInstance
        .get(`public/website-settings?device=${filters.device}${filtersQuery}`)
        .then(({ data }) => {
          this.website = data.website
          this.localization = data.localization
          this.pages = data.pages
          this.shop_status = data.shop_status
          this.trackers = data.trackers
          this.external_resources = data.external_resources
          this.company = data.company
          this.stores = data.stores
          this.has_banners = data.has_banners
          this.banners = data.banners
          this.delivery_time = data.delivery_time
          this.pickup_time = data.pickup_time
          this.meta_tags = data.meta_tags
          this.show_pages.events = data.design_settings.events
          this.show_pages.about_us = data.design_settings.about_us
          this.show_pages.reservations = data.design_settings.reservations
          this.show_pages.jobs = data.design_settings.jobs
          this.footer_content = data.design_settings.footer_content
          this.auto_open_geolocation = data.website.auto_open_geolocation
          this.has_vouchers = data.has_vouchers
          this.socialMediaAccounts = data.social_media_accounts
          if (data.loyalty !== null) {
            this.loyalty = data.loyalty
            this.loyalty.active = data.loyalty.active === 1
          }
          this.has_allergens = data.has_allergens
          this.assets_url = data.assets_url
          return {
            menus: data.menus,
            categories: data.categories,
            store_id: data.store_id,
            companyId: data.website.company_id,
            delivery_now: data.shop_status.delivery_now
          }
        })
        .catch(() => {
          console.log('API_URL')
          console.log(process.env.API_URL)
          // console.log(err)
          return { menus: [], categories: [], store_id: null, companyId: null, delivery_now: false }
        })
    },
    updateShopStatus() {
      return axiosInstance
        .get(`public/website-settings/shop-status`)
        .then(({ data }) => {
          this.shop_status = data.shop_status
        })
        .catch(err => {
          if (process.env.NODE_ENV === 'development') {
            console.log(err)
          }
        })
    },
    setAcceptWebp(data) {
      this.accept_webp = data.result
    },
    setCurrentDomain(data) {
      this.current_domain = data
    }
  }
})
