import { boot } from 'quasar/wrappers'

export function formatCurrency(value, currency, decimals = 0) {
  value = Number(value)
  value = value.toFixed(2)
  const valueArray = value.split('.')
  if (valueArray[1] === '00' && decimals === 0) {
    value = valueArray[0]
  }
  switch (currency) {
    case 'RON':
      return `${value} lei`
    case 'USD':
    case 'MXN':
      return `$${value}`
    case 'GBP':
      return `${value} £`
    case 'EUR':
      return `${value} €`
    default:
      return `${value} ${currency}`
  }
}

export default boot(({ app }) => {
  app.config.globalProperties.initGoogleMaps = () => {}
  app.config.globalProperties.$mathRound = (value, decimals) => {
    let aux
    switch (decimals) {
      case 2:
        aux = 100
        break
      case 3:
        aux = 1000
        break
      case 4:
        aux = 10000
        break
      default:
        aux = 100
    }
    return Math.round(value * aux) / aux
  }
  app.config.globalProperties.$decimalSeparator = number => {
    number = number.replace(',', '.')
    return number
  }
  app.config.globalProperties.$numericInput = $event => {
    const charCode = $event.which ? $event.which : $event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
      $event.preventDefault()
    }
    if (charCode === 46 && $event.target.value.endsWith('.')) {
      $event.preventDefault()
    }
  }
  app.config.globalProperties.$alphaInput = $event => {
    const charCode = $event.which ? $event.which : $event.keyCode
    if (!(charCode >= 65 && charCode <= 120) && charCode !== 45 && charCode !== 0) {
      $event.preventDefault()
    }
  }
  app.config.globalProperties.$preventPaste = $event => {
    $event.preventDefault()
    return false
  }
  app.config.globalProperties.$capitalize = s => {
    if (typeof s !== 'string') {
      return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  app.config.globalProperties.$formatCurrency = formatCurrency
  // validation errors
  app.config.globalProperties.$hasError = (errors, field) => {
    const proto = errors.constructor.prototype
    return field in errors && (!(field in proto) || proto[field] !== errors[field])
  }
  app.config.globalProperties.$showError = (errors, field) => {
    const proto = errors.constructor.prototype
    if (field in errors && (!(field in proto) || proto[field] !== errors[field])) {
      return errors[field][0]
    }
    return ''
  }
  app.config.globalProperties.$hasErrorArray = (errors, field) => {
    return Object.keys(errors).some(k => k.indexOf(field) !== -1)
  }
  app.config.globalProperties.$showErrorArray = (errors, field) => {
    for (var key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key) && key.indexOf(field) !== -1) {
        return errors[key][0]
      }
    }
  }
  app.config.globalProperties.$assetsUrl = () => {
    return process.env.ASSETS_URL
  }
})
