import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import { Cookies } from 'quasar'
import { useSettingsStore } from 'src/stores/settings'

let i18n = createI18n({})

export default boot(({ app, ssrContext, store }) => {
  let storeSettings = useSettingsStore(store)
  let lang = 'en'
  if (storeSettings.localization.language !== null && storeSettings.localization.language !== 'null') {
    lang = storeSettings.localization.language
  } else {
    let cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies
    if (cookies.has('be_lang')) {
      lang = cookies.get('be_lang')
    }
    cookies = null
  }

  // Create I18n instance
  i18n = createI18n({
    locale: lang,
    fallbackLocale: 'en',
    messages,
    warnHtmlInMessage: 'off',
    globalInjection: true
  })

  // Tell app to use the I18n instance
  app.use(i18n)

  // garbage collection
  storeSettings = null
})

export { i18n }
