import { defineStore } from 'pinia'
import { axiosInstance } from 'boot/axios'

export const useCategoriesStore = defineStore('categories', {
  state: () => ({
    items: [],
    selected: {
      name: '',
      url: '',
      id: null
    }
  }),
  actions: {
    fetch(filters) {
      let filtersQuery = ''
      if (filters.translateLanguage !== null) {
        filtersQuery += `&translate_language=${filters.translateLanguage}`
      }
      return axiosInstance
        .get(`public/categories?menu_id=${filters.menuId}${filtersQuery}`)
        .then(({ data }) => {
          this.items = data.categories
        })
        .catch(() => {
          this.items = []
        })
    },
    setSelected(category) {
      this.selected = category
    },
    setItems(items) {
      this.items = items
    }
  }
})
