import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

import { useUserStore } from 'stores/user'

import routes from './routes'

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition
  }
  const position = {}

  // scroll to anchor by returning the selector
  if (to.hash) {
    position.selector = to.hash

    // bypass #1number check
    if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
      return position
    }

    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    return false
  }

  return new Promise(resolve => {
    // check if any matched route config has meta that requires scrolling to top
    if (to.matched.some(m => m.meta.scrollToTop)) {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      position.x = 0
      position.y = 0
    }
    resolve(position)
  })
}

export default ({ store }) => {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory

  const Router = createRouter({
    scrollBehavior,
    routes,

    // Leave this as is and make changes in quasar.config.js instead!
    // quasar.config.js -> build -> vueRouterMode
    // quasar.config.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })

  Router.beforeEach((to, from, next) => {
    const userStore = useUserStore(store)
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (userStore.token !== null) {
        next()
      } else {
        next('/auth/login')
      }
    } else {
      next()
    }
  })

  Router.onError((error, to) => {
    if (
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed') ||
      error.message.includes('Importing a module script failed')
    ) {
      window.location = to.fullPath
    }
  })

  return Router
}
