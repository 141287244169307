import { boot } from 'quasar/wrappers'
import axios from 'axios'
import { Cookies, Notify, LocalStorage } from 'quasar'
import { i18n } from 'src/boot/i18n'
import { useCartStore } from 'stores/cart'
import { useUserStore } from 'stores/user'

let axiosInstance = null

export default boot(({ app, ssrContext, router, store }) => {
  axiosInstance = axios.create({ baseURL: process.env.API_URL })

  axiosInstance.defaults.headers.Accept = 'application/json'
  axiosInstance.defaults.headers['X-Client-Version'] = '6.0.2'
  if (process.env.SERVER) {
    axiosInstance.defaults.headers['X-Origin-Domain'] = ssrContext.req.headers.host
  } else if (process.env.BOOSTEAT_MODE === 'table-ordering') {
    if (typeof window.location.search !== 'undefined' && window.location.search.indexOf('store_token') !== -1) {
      let storeToken = window.location.search.replace('store_token=', '')
      if (storeToken.indexOf('&')) {
        storeToken = storeToken.split('&')[0]
      }
      storeToken = storeToken.replace('?', '')
      LocalStorage.set('store_token', storeToken)
      axiosInstance.defaults.headers['X-STORE-TOKEN'] = storeToken
    } else if (LocalStorage.has('store_token')) {
      axiosInstance.defaults.headers['X-STORE-TOKEN'] = LocalStorage.getItem('store_token')
    }
    axiosInstance.defaults.headers['X-APP-TYPE'] = process.env.BOOSTEAT_MODE
  }

  axiosInstance.interceptors.request.use(function (config) {
    if (typeof config.headers['X-Store-Id'] === 'undefined') {
      const cartStore = useCartStore(store)
      const userStore = useUserStore(store)

      const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies

      if (cookies.has('translate_language') && cookies.get('translate_language').length === 5) {
        const lang = cookies.get('translate_language').split('-')[0]
        if (['es', 'lt', 'en', 'ro'].indexOf(lang) !== -1) {
          config.headers['Content-Language'] = lang
        } else {
          config.headers['Content-Language'] = 'en'
        }
      } else if (cookies.has('be_lang') && cookies.get('be_lang') !== 'undefined') {
        config.headers['Content-Language'] = cookies.get('be_lang')
      }

      if (cartStore.store_id !== null) {
        config.headers['X-Store-Id'] = cartStore.store_id
      }
      if (process.env.BOOSTEAT_MODE === 'table-ordering') {
        config.headers['X-Delivery-Type'] = 'table'
      } else if (cartStore.delivery_type !== null) {
        config.headers['X-Delivery-Type'] = cartStore.delivery_type
      }

      if (userStore.token !== null) {
        config.headers.Authorization = `Bearer ${userStore.token}`
      }
    }

    return config
  })

  if (process.env.SERVER === false) {
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          if (
            error.response.status !== 422 &&
            typeof error.response.data.message !== 'undefined' &&
            error.response.data.message.length > 0
          ) {
            Notify.create({
              message: error.response.data.message,
              position: 'top',
              color: 'negative'
            })
          }
          if (error.response.status === 401) {
            store.dispatch('settings/logoutClient')
            router.push('/auth/login')
          } else if (error.response.status === 403) {
            Notify.create({
              message: `${i18n.global.t('axios.403')}<br/>Url: ${error.response.config.method.toUpperCase()} ${
                error.response.config.url
              }`,
              html: true,
              timeout: 7000,
              position: 'top',
              color: 'negative'
            })
          } else if (error.response.status === 404) {
            router.push({ name: 'homepage' })
          } else if (error.response.status === 524) {
            Notify.create({
              message: i18n.global.t('axios.524'),
              position: 'top',
              color: 'negative'
            })
          } else if (error.response.status !== 422) {
            if (typeof error.response.data.message !== 'undefined' && error.response.data.message.length > 0) {
              Notify.create({
                message: error.response.data.message,
                position: 'top',
                color: 'negative'
              })
            } else {
              Notify.create({
                message: i18n.global.t('axios.has-unkown-error'),
                position: 'top',
                color: 'negative'
              })
            }
          }
        } else if (error.request) {
          Notify.create({
            message: i18n.global.t('axios.no-internet'),
            position: 'top',
            color: 'negative'
          })
        } else if (typeof error.message !== 'undefined' && error.message.length > 0) {
          Notify.create({
            message: error.message,
            position: 'top',
            color: 'negative'
          })
        } else {
          Notify.create({
            message: i18n.global.t('axios.unknown-error'),
            position: 'top',
            color: 'negative'
          })
        }
        return Promise.reject(error)
      }
    )
  } else {
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (process.env.NODE_ENV === 'development') {
          console.log(error)
        }
        return Promise.reject(error)
      }
    )
  }
  app.config.globalProperties.$axios = axiosInstance
})

export { axiosInstance }
