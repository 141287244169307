import { store } from 'quasar/wrappers'
import { Cookies } from 'quasar'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

export default store(({ ssrContext }) => {
  const pinia = createPinia()
  pinia.use(
    createPersistedState({
      storage: {
        getItem(key) {
          const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies
          return JSON.stringify(cookies.get(`be_${process.env.BOOSTEAT_MODE}_${key}`))
        },
        setItem(key, value) {
          const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies
          cookies.set(`be_${process.env.BOOSTEAT_MODE}_${key}`, value, {
            expires: 365,
            path: '/',
            sameSite: 'Strict',
            secure: process.env.NODE_ENV !== 'development'
          })
        },
        removeItem(key) {
          const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies
          if (cookies.has(`be_${process.env.BOOSTEAT_MODE}_${key}`)) {
            cookies.remove(`be_${process.env.BOOSTEAT_MODE}_${key}`, {
              expires: 365,
              path: '/',
              sameSite: 'Strict',
              secure: process.env.NODE_ENV !== 'development'
            })
          }
        }
      }
    })
  )

  return pinia
})
