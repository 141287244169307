import en from './en'
import ro from './ro'
import es from './es'
import lt from './lt'

export default {
  en,
  ro,
  es,
  lt
}
